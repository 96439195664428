<template>
	<v-fade-transition hide-on-leave>
		<template v-if="loadingParent">
			<v-skeleton-loader key="transitionCard" type="card" class="rounded-xl" />
		</template>
		<template v-else>
			<v-card key="transitionEnded" class="mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" style="width: 100%" rounded="xl">
				<v-card-title>
					<span>{{ $t('companies.sectors') }}</span>
				</v-card-title>
				<v-card-text>
					<div key="filteresResults" class="d-flex flex-wrap justify-start mb-2">
						<v-chip small v-for="sector in company.info.sectors" :key="`sector${sector.id}`" class="mx-1 my-1">
							{{ sector.name }}
						</v-chip>
					</div>
				</v-card-text>
			</v-card>
		</template>
	</v-fade-transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'CompanySectors',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		...mapGetters({
			company: 'companies/company',
		}),
	},
}
</script>
